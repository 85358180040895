@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import "~perfect-scrollbar/css/perfect-scrollbar.css";


/* CHROME SCROLLBAR */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background: rgb(82, 82, 82);
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(170, 170, 170);
}

*{
  /* width: 100%; */
  margin: 0;
  padding: 0;
  /* background-color: #111111; */
  /* color: #222; */
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
  scroll-behavior: smooth;
}

input[type='checkbox'] {
  accent-color: #b31d15;
}

/* @media (max-width: 575.98px) { ... }

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) { ... }

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) { ... }

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) { ... } */
@media (max-width: 1199.98px) {
  *{
    font-size: 2px;
  }
}

/* // X-Large devices (large desktops, less than 1400px) */
@media (max-width: 1399.98px) { 
  *{
    font-size: 14px;
  }
}

body {
  /* width: 100% !important; */
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.slider-right div{
  border: 0px solid black !important;
}

.slider-left div{
  border: 0px solid black !important;
}

.slider-single-content{
  box-shadow: none !important;
}